import * as THREE from "three";
import { TransformControls } from "three/addons/controls/TransformControls.js";

import Experience from "./Experience.js";
import EventEmitter from "./Utils/EventEmitter.js";
import Contentful from "../classes/Contentful";

export default class Manager extends EventEmitter {
  constructor() {
    super();
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.camera = this.experience.camera;
    this.scene = this.experience.scene;
    this.canvas = this.experience.canvas;
    this.contentful = new Contentful();
    // this.admin = window.location.hash === "#admin";
    this.admin = false;
    this.interactiveMeshes = [];

    // Setup
    // this.initTransformControls();

    // Debug
    // this.setDebug();
  }

  initTransformControls() {
    if (this.debug.active || this.admin) {
      this.transformControls = new TransformControls(
        this.camera.instance,
        this.canvas
      );
      this.transformControls.enabled = true;
      this.transformControls.size = 0.5;
      this.scene.add(this.transformControls);
    }
  }

  setActiveMesh(mesh) {
    if (this.transformControls.enabled) {
      this.transformControls.attach(mesh);
    }
  }

  addClickEventToMesh(mesh, clickHandlerFunction) {
    if (!this.interactionManager) {
      this.interactionManager = this.experience.renderer.interactionManager;
    }

    mesh.addEventListener("mouseover", (event) => {
      document.body.style.cursor = "pointer";
    });

    mesh.addEventListener("mouseout", (event) => {
      document.body.style.cursor = "grab";
    });

    mesh.addEventListener("click", (event) => {
      if (typeof clickHandlerFunction === "function") {
        clickHandlerFunction(event);
      }
    });

    // we add the mesh to the interaction Manager if it's not already there
    if (!this.interactiveMeshes.includes(mesh.name)) {
      this.interactiveMeshes.push(mesh.name);
      this.interactionManager.add(mesh);
    }
  }

  setDebug() {
    if (this.debug.active || this.admin) {
      this.transformControls.addEventListener("mouseDown", () => {
        this.camera.controls.enabled = false;
      });

      this.transformControls.addEventListener("mouseUp", () => {
        this.camera.controls.enabled = true;
        const mesh = this.transformControls.object;
        const editId = mesh.editId;
        const name = mesh.name;

        const position = {
          x: mesh.position.x,
          y: mesh.position.y,
          z: mesh.position.z,
        };
        console.log(
          `Admin: Save ${name}/${editId} to new position ${mesh.position.x}, ${mesh.position.y}, ${mesh.position.z}`
        );
        //  Example: Updating an entry
        this.contentful
          .updateEntry(editId, {
            position: {
              "en-US": position, // Update with the correct field and locale
            },
          })
          .then((updatedEntry) => {
            console.log("Updated entry:", updatedEntry);
          });
      });
    }
  }

  update() {}
}
