export default {
  en: {
    hotspot_1:
      "Textured rubber grip ensures a comfortable hold and prevents slipping during use, providing a secure and ergonomic handling experience.",
    hotspot_2:
      "Crafted from precision aluminum for a sleek, durable body that offers a premium feel and lasting quality.",
    hotspot_3:
      "Interchangeable head designed for precise eye surgery, allowing versatility and easy tool customization.",
  },
};
