<template>
  <div class="hotspots" @mousemove="moveCursor">
    <div
      class="hotspots__cursor"
      ref="customCursor"
      :class="{ show: show, prev: arrowNext }"
    >
      <div class="hotspots__cursor-arrow"></div>
    </div>
    <template v-for="(hotspot, index) in hotspots" :key="index" v-if="!admin">
      <div
        :class="[
          'hotspot',
          `hotspot-${hotspot.id}`,
          { active: hotspot.id === activeId },
        ]"
        @click="setActive(hotspot.id)"
      >
        <div class="hotspot__label">{{ hotspot.id + 1 }}</div>
      </div>
    </template>
    <div
      class="hotspots__clickable"
      @mouseenter="showCursor"
      @mouseleave="hideCursor"
    >
      <Transition name="fade">
        <div
          v-if="loaded"
          class="hotspots__buttons"
          @mouseenter="hideCursor"
          @mouseleave="showCursor"
          :class="{ short: model == 'short', long: model == 'long' }"
        >
          <button
            @click="setModel('short')"
            :class="{ active: model == 'short' }"
          >
            Short
          </button>
          <button
            @click="setModel('long')"
            :class="{ active: model == 'long' }"
          >
            Long
          </button>
        </div>
      </Transition>
      <Transition name="fade">
        <ul class="hotspots__navigation" v-if="activeHotspot">
          <li
            v-for="(hotspot, index) in hotspots"
            :key="index"
            :class="{ active: activeHotspot.id == index }"
          >
            <button @click="navTo(index)">
              {{ index }}
            </button>
          </li>
        </ul>
      </Transition>
      <Transition name="fade">
        <div
          v-if="loaded"
          class="hotspots__nav"
          @mouseenter="hideCursor"
          @mouseleave="showCursor"
        >
          <ul>
            <li>
              <button></button>
            </li>
            <li>
              <button></button>
            </li>
            <li>
              <button></button>
            </li>
            <li>
              <button></button>
            </li>
            <li>
              <button></button>
            </li>
          </ul>
        </div>
      </Transition>
      <div
        class="hotspots__clickable--left"
        @mouseenter="enterLeft"
        @click="goToPrev()"
      ></div>
      <div
        class="hotspots__clickable--right"
        @mouseenter="enterRight"
        @click="goToNext()"
      ></div>
    </div>
  </div>
</template>

<script>
import Contentful from "../classes/Contentful";

export default {
  name: "Hotspots",
  props: {
    manager: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      hotspots: [],
      isContentLoaded: false,
      contentful: new Contentful(),
      admin: this.manager.admin,
      activeId: null,
      loopIndex: 0, // Initialize the loopIndex to handle null first
      show: false,
      arrowNext: true,
      model: "long",
      loaded: false,
      activeHotspot: null,
    };
  },
  methods: {
    moveCursor(event) {
      const cursor = this.$refs.customCursor;
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      cursor.style.left = `${mouseX}px`;
      cursor.style.top = `${mouseY}px`;
    },
    showCursor() {
      this.show = true;
    },
    hideCursor() {
      this.show = false;
    },
    enterLeft() {
      this.arrowNext = true;
    },
    enterRight() {
      this.arrowNext = false;
    },
    setActive(id) {
      this.activeId = id;
      this.manager.trigger("sidebar-setActiveHotSpot", id);
    },
    setActiveHotSpot(id) {
      this.activeHotspot = this.hotspots[id];
    },
    setModel(model) {
      if (model) {
        this.model = model;
        this.manager.trigger("oertli-setPen", this.model);
      }
    },
    goToPrev() {
      // Decrement the loop index, using modulo to handle the wrap-around
      this.loopIndex = (this.loopIndex - 1 + 4) % 4;

      // Set activeId based on the loopIndex
      if (this.loopIndex === 0) {
        this.activeId = null; // Set to null when loopIndex is 0
      } else {
        this.activeId = this.loopIndex - 1; // Adjust for 0, 1, 2
      }

      this.manager.trigger("sidebar-setActiveHotSpot", this.activeId);
      this.manager.trigger("oertli-goToPrev");
    },
    goToNext() {
      // Increment the loop index
      this.loopIndex = (this.loopIndex + 1) % 4;

      // Set activeId based on the loopIndex
      if (this.loopIndex === 0) {
        this.activeId = null; // Set to null when loopIndex is 0
      } else {
        this.activeId = this.loopIndex - 1; // Adjust for 0, 1, 2
      }
      this.manager.trigger("sidebar-setActiveHotSpot", this.activeId);
      this.manager.trigger("oertli-goToNext");
    },
  },
  mounted() {
    this.manager.on("hotspots-setHotspots", (hotspots) => {
      this.hotspots = hotspots;
    });
    this.manager.on("sidebar-setActiveHotSpot", (id) => {
      this.setActiveHotSpot(id);
    });
    this.manager.on("ready", () => {
      this.loaded = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.hotspots {
  position: fixed;
  width: 100vw;
  height: var(--vh);
  top: 0;
  // left: var(--sidebar-width);
  pointer-events: none;
  z-index: $z-ui;

  &__buttons {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 95px;
    left: 50%;
    transform: translateX(-50%);
    z-index: $z-ui + 2;
    background: #0000001a;
    border-radius: 5px;

    &.long:after {
      transform: translateX(100%);
    }

    &:after {
      position: absolute;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
      border-radius: 5px;
      background: #0000001a;
      cursor: pointer;
      transform: translateX(0%);
      transition: transform 150ms ease-in-out;
    }

    button {
      border: none;
      background: none;
      width: 95px;
      padding: 8px 14px 8px 14px;
      text-transform: uppercase;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1px;
      cursor: pointer;
      color: #00000099;
      transition: color 150ms ease-in-out;

      &.active {
        color: var(--main-text-color);
      }
    }
  }

  &__navigation {
    position: absolute;
    bottom: 170px;
    left: 50%;
    transform: translateX(-50%);
    list-style: none;
    display: flex;

    li {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
      button {
        font-size: 0;
        background: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        border: none;
        background: #00000033;
        transition: background 150ms ease-in-out;
      }

      &.active button {
        background: var(--main-text-color);
      }
    }
  }

  &__nav {
    position: absolute;
    bottom: 95px;
    left: 50%;
    transform: translateX(-50%);
    z-index: $z-ui + 2;

    ul {
      list-style: none;
      display: flex;
      flex-direction: row;

      li {
        &:nth-child(1) {
          button {
            background-image: url("/assets/ui/0.png");
          }
        }
        &:nth-child(2) {
          button {
            background-image: url("/assets/ui/1.png");
          }
        }
        &:nth-child(3) {
          button {
            background-image: url("/assets/ui/2.png");
          }
        }
        &:nth-child(4) {
          button {
            background-image: url("/assets/ui/3.png");
          }
        }

        &:nth-child(5) {
          button {
            background-image: url("/assets/ui/4.png");
          }
        }

        button {
          cursor: not-allowed;
          padding: 12px 30px;
          background: none;
          border: none;
          background-repeat: no-repeat;
          background-size: 24px;
          background-position: center center;
        }
      }
    }
  }

  &__cursor {
    position: absolute;
    width: 60px;
    height: 60px;
    margin-top: -30px; /* Half of the height */
    margin-left: -30px; /* Half of the width */
    background-color: var(--main-text-color);
    border-radius: 50%;
    pointer-events: none;
    transform: scale(0, 0);
    transition: transform 300ms ease-out;
    z-index: $z-ui + 1;

    &.show {
      transform: scale(1, 1);
    }

    &-arrow {
      width: 100%;
      height: 100%;
      background-image: url("/assets/ui/arrow.png");
      background-repeat: no-repeat;
      background-size: 40%;
      background-position: center center;
      transition: transform 150ms ease-in-out;
    }

    &.prev .hotspots__cursor-arrow {
      transform: rotate(-180deg);
    }
  }

  &__clickable {
    pointer-events: all;
    position: absolute;
    width: calc(100vw - (var(--sidebar-width) + var(--selector-width)));
    height: 100vh;
    left: var(--sidebar-width);
    z-index: $z-ui;
    cursor: none;

    &--left,
    &--right {
      position: absolute;
      top: 0;
      width: 50%;
      height: 100%;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  .hotspot {
    position: absolute;
    top: 50%;
    left: 50%;
    pointer-events: all;

    &.active {
      .hotspot__label {
        top: -29px;
        left: -29px;
        width: 58px;
        height: 58px;
        background: rgba(242, 71, 43, 0.2);
        &:after {
          width: 36px;
          height: 36px;
          background: rgba(242, 71, 43, 1);
        }
        &:before {
          opacity: 1;
        }
      }
    }

    &.visible {
      .hotspot__label {
        transform: scale(1, 1);
      }
    }

    &__label {
      position: absolute;
      top: -17px;
      left: -17px;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);
      font-size: 0;
      text-align: center;
      cursor: pointer;
      transform: scale(0, 0);
      transition: all 300ms ease-in-out;
      will-change: transform;

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 300ms ease-in-out;
        background: #000000;
      }

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 71px;
        height: 71px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(242, 71, 43, 0.1);
        transition: all 300ms ease-in-out;
        opacity: 0;
      }
    }
  }
}
</style>
