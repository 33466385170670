import Experience from "../Experience";
import Environment from "./Environment";
import Hotspots from "./Hotspots";
// import Loader from "./Loader";
import Oertli from "./Oertli";
import Floor from "./Floor";

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.manager = this.experience.manager;
    // this.loader = new Loader();

    // Wait for resources to be loaded
    this.resources.on("ready", () => {
      // Setup
      this.oertli = new Oertli();
      this.hotspots = new Hotspots();
      this.floor = new Floor();
      this.environment = new Environment();

      // Show Experience
      // this.loader.hideLoader();
      // this.manager.trigger("loader-hide");
    });
  }

  update() {
    if (this.oertli) this.oertli.update();
    if (this.hotspots) this.hotspots.update();
  }
}
