import { createClient } from "contentful";
import { createClient as createManagementClient } from "contentful-management";

const space = "ysp7j6kqcb1r";
const accessToken = "w1ffVxddNguKUO4OKfWcg3ff8vEy0bkCeQ81tCxwvq8";
const managementAccessToken =
  "CFPAT-j6_33PHYowPlvuadQCKjcPqu5g8cc7zcUPAY0B91imY";
let instance = null;

export default class Contentful {
  constructor() {
    // Singleton
    if (instance) {
      return instance;
    }
    instance = this;

    this.client = createClient({
      space: space,
      accessToken: accessToken,
    });

    // Content Management API client for updating entries
    this.managementClient = createManagementClient({
      accessToken: managementAccessToken,
    });
  }

  async getContent() {
    return await this.client.getEntries().then((response) => {
      return response;
    });
  }

  async updateEntry(entryId, updatedFields) {
    try {
      const space = await this.managementClient.getSpace("ysp7j6kqcb1r");
      const environment = await space.getEnvironment("master"); // Replace 'master' with your environment ID if needed

      const entry = await environment.getEntry(entryId);

      // Update fields
      Object.keys(updatedFields).forEach((field) => {
        entry.fields[field] = updatedFields[field];
      });

      // Save and publish the updated entry
      const updatedEntry = await entry.update();
      await updatedEntry.publish();

      console.log("Entry updated and published:", updatedEntry);
      return updatedEntry;
    } catch (error) {
      console.error("Error updating entry:", error);
      throw error;
    }
  }
}
