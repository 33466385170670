import * as THREE from "three";
import Experience from "../Experience";
// import Contentful from "../../classes/Contentful";

export default class Hotspots {
  constructor() {
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.scene = this.experience.scene;
    this.time = this.experience.time;
    this.resources = this.experience.resources;
    this.camera = this.experience.camera.instance;
    this.sizes = this.experience.sizes;
    this.manager = this.experience.manager;
    this.admin = this.experience.manager.admin;
    // this.raycaster = new THREE.Raycaster();
    // this.contentful = new Contentful();
    this.hotspots = [];
    this.sceneReady = false;
    this.isContentLoaded = false;

    // Options
    this.options = {};

    // Setup
    this.resource = this.resources.items.hotspotsModel;

    this.initEvents();
    // this.getContent();
    this.setModel();

    // Debug
    // this.setDebug();
  }

  initEvents() {
    this.manager.on("hotspot-show", () => {
      this.sceneReady = true;
    });
  }

  async getContent() {
    await this.contentful.getContent().then((response) => {
      this.isContentLoaded = true;
      const content = response.items;
      this.hotspots = content
        .filter((entry) => entry.sys.contentType.sys.id === "hostpot")
        .map((entry) => ({
          ...entry.fields,
          id: entry.sys.id, // Add the id from sys object
          locale: entry.sys.locale, // Add the locale from sys object
        }))
        .sort((a, b) => a.id - b.id);

      if (this.admin) {
        this.setAdminHotspots();
      }
    });
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        const pos = {
          id: child.name.split("_")[1] - 1,
          name: child.name,
          position: child.position,
        };
        this.hotspots.push(pos);
      }
    });
    this.hotspots.sort((a, b) => a.id - b.id);
    this.manager.trigger("hotspots-setHotspots", this.hotspots);
    this.isContentLoaded = true;
  }

  setAdminHotspots() {
    this.hotspots.forEach((point, index) => {
      const geometry = new THREE.OctahedronGeometry(1, 0);
      const material = new THREE.MeshBasicMaterial({ color: 0xff00ff });
      const mesh = new THREE.Mesh(geometry, material);
      mesh.name = `hotspot_${index + 1}`;
      mesh.editId = point.id;
      mesh.locale = point.locale;
      mesh.position.set(point.position.x, point.position.y, point.position.z);
      mesh.scale.set(0.01, 0.015, 0.01);
      this.manager.addClickEventToMesh(mesh, () => {
        this.manager.setActiveMesh(mesh);
      });
      this.scene.add(mesh);
    });
  }

  setDebug() {
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("Hotspots");
    }
  }

  update() {
    if (this.sceneReady && this.isContentLoaded && !this.admin) {
      this.hotspots.forEach((hotspot, index) => {
        const domElement = document.querySelector(`.hotspot-${index}`);
        if (domElement) {
          const position = new THREE.Vector3(
            hotspot.position.x,
            hotspot.position.y,
            hotspot.position.z
          );
          const screenPosition = position.clone();
          screenPosition.project(this.camera);
          domElement.classList.add("visible");
          // this.raycaster.setFromCamera(screenPosition, this.camera);

          // const intersects = this.raycaster.intersectObjects(
          //   this.scene.children,
          //   true
          // );
          // if (intersects.length === 0) {
          //   domElement.classList.add("visible");
          // } else {
          //   const intersectionDistance = intersects[0].distance;
          //   const pointDistance = position.distanceTo(this.camera.position);
          //   if (intersectionDistance < pointDistance) {
          //     domElement.classList.remove("visible");
          //   } else {
          //     domElement.classList.add("visible");
          //   }
          // }

          const translateX = screenPosition.x * this.sizes.width * 0.5;
          const translateY = -screenPosition.y * this.sizes.height * 0.5;

          domElement.style.transform = `translate(${translateX}px, ${translateY}px)`;
        }
      });
    }
  }
}
