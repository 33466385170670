export default [
  {
    name: "oertliModel",
    type: "gltfModel",
    path: "/models/oertli/oertli_camera_reversed.glb",
  },
  {
    name: "hotspotsModel",
    type: "gltfModel",
    path: "/models/hotspots/hotspots.glb",
  },
];
