<template>
  <div class="selector">
    <Transition name="fade">
      <div v-if="loaded" class="selector__options">
        <ul>
          <li :class="{ active: tipIndex == 0 }">
            <button @click="selectTip(0)"></button>
            <span>Don't show tip</span>
          </li>
          <li :class="{ active: tipIndex == 1 }">
            <button @click="selectTip(1)"></button>
            <span>Diathermy tip Eraser</span>
          </li>
          <li :class="{ active: tipIndex == 2 }">
            <button @click="selectTip(2)"></button>
            <span>Capsulotomy tipp</span>
          </li>
          <li :class="{ active: tipIndex == 3 }">
            <button @click="selectTip(3)"></button>
            <span>Diathermy tip Eraser</span>
          </li>
          <li :class="{ active: tipIndex == 4 }">
            <button @click="selectTip(4)"></button>
            <span>Diathermy tip Eraser</span>
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "selector",
  props: {
    manager: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      tipIndex: 2,
      loaded: false,
    };
  },
  methods: {
    selectTip(index) {
      if (index || index === 0) {
        this.tipIndex = index;
        this.manager.trigger("oertli-setTip", index);
      }
    },
  },
  mounted() {
    this.manager.on("ready", () => {
      this.loaded = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.selector {
  display: flex;
  width: var(--selector-width);
  height: var(--vh);
  z-index: $z-ui;
  margin-left: auto;
  padding: 45px 45px 45px 0;

  &__options {
    margin: auto;

    ul {
      list-style: none;

      li {
        text-align: center;
        max-width: 100px;
        margin-bottom: 15px;

        &.active button {
          border: 2px solid black;
        }

        &:nth-child(odd) {
          button {
            background-image: url("/assets/tip/1.png");
          }
        }

        &:nth-child(even) {
          button {
            background-image: url("/assets/tip/2.png");
          }
        }

        &:first-child {
          button {
            background-image: url("/assets/tip/0.png");
          }
        }
      }
    }

    button {
      cursor: pointer;
      width: 80px;
      height: 80px;
      border: 2px solid transparent;
      transition: border 150ms ease-in-out;
      background-color: #0000000d;
      border-radius: 3px;
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center center;
    }

    span {
      display: block;
      margin-top: 10px;
      color: #00000099;
      font-weight: 700;
      font-size: 13px;
      letter-spacing: 0.05em;
      line-height: 150%;
    }
  }
}
</style>
