varying vec3 vPosition;

uniform vec3 uColor;
uniform float minX;
uniform float maxX;

void main() {
  // Normalize x position to [0, 1] range based on the mesh's bounds
  float xGradient = (vPosition.x - minX) / (maxX - minX);

  gl_FragColor = vec4(uColor, xGradient);

  #include <tonemapping_fragment>
  #include <colorspace_fragment>
}
