<script setup>
// Vue Components
import hotspots from "./components/hotspots.vue";
import sidebar from "./components/sidebar.vue";
import selector from "./components/selector.vue";

// Three.js app
import Experience from "./Experience/Experience";
const experience = new Experience();
const manager = experience.manager;
</script>

<template>
  <div class="container">
    <sidebar :manager="manager"></sidebar>
    <hotspots :manager="manager"></hotspots>
    <selector :manager="manager"></selector>
  </div>
</template>

<style lang="scss">
#app {
  position: relative;
  width: 100vw;
  height: var(--vh);

  &:before,
  &:after {
    pointer-events: none;
    display: none;
    content: "";
    position: absolute;
    z-index: $z-disclaimer;

    @include small-only {
      display: block;
    }
  }

  &:before {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--main-bg-color);
  }

  &:after {
    bottom: 0;
    left: 0;
    font-weight: 500;
    font-size: 15vw;
    content: "This is a Desktop Experiment. (for\00a0now)";
    color: var(--main-text-color);
    max-width: 25vw;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: var(--vh);
}
</style>
