<template>
  <div class="sidebar">
    <div class="sidebar__content">
      <h4 :class="{ show: activeHotspot }">what is this</h4>
      <Transition name="fade">
        <div v-if="isContentLoaded" class="sidebar__infowindow">
          <h3>
            <template v-if="activeHotspot">Details</template>
            <template v-else>Diathermy handpiece</template>
          </h3>
          <p>
            <template v-if="activeHotspot">{{
              activeHotspot.description
            }}</template>
          </p>
        </div>
      </Transition>
      <Transition name="fade">
        <div class="sidebar__table" v-if="isContentLoaded && product">
          <ul>
            <li>
              <h5>Instrument</h5>
            </li>
            <li>
              <span>Length</span>
              <span>{{ product["length"] }}</span>
            </li>
            <li>
              <span>Diameter</span>
              <span>{{ product.diameter }}</span>
            </li>
            <li class="noborder">
              <span>Material</span>
              <span>{{ product.material }}M</span>
            </li>
            <li>
              <h5>Cord</h5>
            </li>
            <li>
              <span>Length</span>
              <span>{{ product["length"] }}</span>
            </li>
            <li>
              <span>Diameter</span>
              <span>{{ product.diameter }}</span>
            </li>
            <li class="noborder">
              <span>Material</span>
              <span>{{ product.material }}</span>
            </li>
            <li>
              <h5>Article</h5>
            </li>
            <li>
              <span>Article Number</span>
              <span>{{ product.articleNumber }}</span>
            </li>
          </ul>
          <button class="sidebar__download">
            <span>DOWNLOAD FOR OFFLINE USE</span>
          </button>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import Contentful from "../classes/Contentful";

export default {
  name: "sidebar",
  props: {
    manager: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      hotspots: [],
      products: [],
      isContentLoaded: false,
      contentful: new Contentful(),
      activeHotspot: null,
      product: null,
    };
  },
  methods: {
    setActiveHotSpot(id) {
      this.activeHotspot = this.hotspots[id];
    },
    getProductByName(name) {
      return this.products.find((product) => product.model === name);
    },
    async getContent() {
      this.contentful.getContent().then((response) => {
        const content = response.items;
        this.hotspots = content
          .filter((entry) => entry.sys.contentType.sys.id === "hostpot")
          .map((entry) => entry.fields)
          .sort((a, b) => a.id - b.id);

        this.products = content
          .filter((entry) => entry.sys.contentType.sys.id === "product")
          .map((entry) => entry.fields);

        this.product = this.getProductByName("long");
        this.isContentLoaded = true;
        this.manager.trigger("content-loaded");
      });
    },
  },
  mounted() {
    // init Events
    this.manager.on("ready", () => {
      this.getContent();
    });
    this.manager.on("sidebar-setActiveHotSpot", (id) => {
      this.setActiveHotSpot(id);
    });
    this.manager.on("oertli-setPen", (model) => {
      this.product = this.getProductByName(model);
    });
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: var(--sidebar-width);
  height: var(--vh);
  z-index: $z-ui;

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 45px 0 45px 45px;

    h3 {
      font-size: clamp(1.5rem, 0.9278rem + 1.831vw, 3.125rem);
      line-height: 110%;
      font-weight: 500;
      letter-spacing: 0.5;
      padding-bottom: 15px;
    }

    h4 {
      text-transform: uppercase;
      color: var(--secondary-text-color);
      font-size: 12px;
      line-height: 150%;
      padding-bottom: 15px;
      font-weight: 500;
      letter-spacing: 1px;
      opacity: 0;
      transition: opacity 300ms ease-in-out;
      &.show {
        opacity: 1;
      }
    }

    p {
      color: var(--secondary-text-color);
      font-size: 15px;
      line-height: 150%;
      font-weight: 400;
      letter-spacing: 1px;
      min-height: 120px;
    }
  }

  &__infowindow {
    min-height: 260px;
  }

  &__table {
    ul {
      list-style: none;

      li {
        display: flex;
        flex-direction: row;
        width: 100%;
        border-bottom: 1px solid #00000033;
        padding: 8px 0;

        &:first-child {
          h5 {
            margin: 0;
          }
        }

        &.noborder {
          border-bottom: none;
        }

        h5 {
          font-weight: 700;
          margin: 30px 0 0 0;
          padding: 0;
          color: var(--main-text-color);
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 13px;
        }

        span {
          font-weight: 700;
          width: 50%;
          font-size: 13px;
          letter-spacing: 0.05em;

          &:first-child {
            color: var(--secondary-text-color);
          }
        }
      }
    }
  }

  &__download {
    position: relative;
    border: none;
    background: none;
    font-weight: 500;
    font-size: 12px;
    margin-top: 40px;
    padding-left: 27px;
    cursor: pointer;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      display: block;
      width: 22px;
      height: 22px;
      background-image: url("/assets/ui/download.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    &:after {
      position: absolute;
      content: "";
      display: block;
      bottom: -5px;
      right: 0;
      width: calc(100% - 27px);
      height: 1px;
      background-color: var(--main-text-color);
      pointer-events: none;
      transition: width 150ms ease-in-out;
    }

    &:hover:after {
      width: 0;
    }

    span {
      text-transform: uppercase;
      // border-bottom: 1px solid var(--main-text-color);
    }
  }
}
</style>
