import * as THREE from "three";
import Experience from "../Experience";
import { RoomEnvironment } from "three/addons/environments/RoomEnvironment.js";

export default class Environment {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;
    this.renderer = this.experience.renderer;

    this.options = {
      dirLightColor: 0xffffff,
      environmentIntensity: 0.32,
    };

    // Setup
    this.setLights();
    this.setEnvMap();

    // Debug
    this.setDebug();
  }

  setEnvMap() {
    this.pmremGenerator = new THREE.PMREMGenerator(this.renderer.instance);
    this.pmremGenerator.compileEquirectangularShader();

    this.neutralEnvironment = this.pmremGenerator.fromScene(
      new RoomEnvironment()
    ).texture;

    this.scene.environment = this.neutralEnvironment;
    this.scene.environmentIntensity = this.options.environmentIntensity;
  }

  setLights() {
    this.dirLight = new THREE.DirectionalLight(0xffffff, 0);
    this.dirLight.castShadow = true;
    this.dirLight.shadow.mapSize.set(2048, 2048);
    this.dirLight.shadow.camera.far = 10;
    this.dirLight.shadow.camera.left = -1;
    this.dirLight.shadow.camera.top = 1;
    this.dirLight.shadow.camera.right = 1;
    this.dirLight.shadow.camera.bottom = -1;
    this.dirLight.shadow.bias = -0.0001;
    this.dirLight.position.set(0, 1.24, -0);
    this.scene.add(this.dirLight);
  }

  setDebug() {
    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("Env");

      this.debugFolder
        .add(this.options, "environmentIntensity")
        .step(0.001)
        .min(0.001)
        .max(2)
        .name("Intensity")
        .onChange(() => {
          this.scene.environmentIntensity = this.options.environmentIntensity;
        });

      this.helper = new THREE.DirectionalLightHelper(
        this.dirLight,
        1,
        0xff00ff
      );
      // this.scene.add(this.helper);
      //Create a helper for the shadow camera (optional)
      const helper = new THREE.CameraHelper(this.dirLight.shadow.camera);
      // this.scene.add(helper);
    }
  }
}
