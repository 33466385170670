import { gsap } from "gsap";
import * as THREE from "three";
import Experience from "../Experience";

import vertexShader from "../../shaders/wire/vertex.glsl";
import fragmentShader from "../../shaders/wire/fragment.glsl";

export default class Oertli {
  constructor() {
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.scene = this.experience.scene;
    this.time = this.experience.time;
    this.camera = this.experience.camera;
    this.resources = this.experience.resources;
    this.manager = this.experience.manager;

    // Options
    this.needles = [];
    this.longPen = [];
    this.shortPen = [];
    this.wires = [];
    this.actions = {};
    this.options = {
      cameraSpeed: 1,
      minX: 12.76,
      maxX: 7.55,
    };
    this.currentActionIndex = 1;

    // Setup
    this.resource = this.resources.items.oertliModel;

    this.wireMaterial = new THREE.ShaderMaterial({
      transparent: false,
      side: THREE.DoubleSide,
      uniforms: {
        uColor: new THREE.Uniform(new THREE.Color(0x000000)),
        minX: new THREE.Uniform(this.options.minX),
        maxX: new THREE.Uniform(this.options.maxX),
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });

    this.initEvents();
    this.setModel();
    this.setAnimations();

    // Debug
    this.setDebug();
  }

  initEvents() {
    this.manager.on("oertli-goToIndex", (index) => {
      this.goToIndex(index);
    });

    this.manager.on("oertli-goToNext", this.goToNext.bind(this));
    this.manager.on("oertli-goToPrev", this.goToPrev.bind(this));
    this.manager.on("oertli-setPen", this.setPen.bind(this));
    this.manager.on("oertli-setTip", this.setTip.bind(this));

    this.manager.on("ready", () => {
      gsap.to(this.model.position, {
        y: 0,
        duration: 1,
        delay: 0.75,
        ease: "power2.out",
        onComplete: () => {
          this.manager.trigger("hotspot-show");
        },
      });
    });
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh && child.name.includes("Floor")) {
        child.visible = false;
        child.receiveShadow = true;
      } else if (child instanceof THREE.Mesh && child.name.includes("wire")) {
        // child.castShadow = true;
        this.wires.push(child);
        child.material = this.wireMaterial;
      } else if (child instanceof THREE.Mesh && child.name.includes("needle")) {
        child.castShadow = true;
        this.needles.push(child);
      } else if (child instanceof THREE.Mesh && child.name.includes("long")) {
        child.castShadow = true;
        // child.visible = false;
        this.longPen.push(child);
      } else if (child instanceof THREE.Mesh && child.name.includes("short")) {
        child.castShadow = true;
        child.visible = false;
        this.shortPen.push(child);
      } else if (child instanceof THREE.Camera) {
        this.cameraModel = child;
        this.camera.setPositionAndRotation(
          this.cameraModel.position,
          this.cameraModel.rotation
        );
      }
    });
    // show only one wire
    this.wires[this.wires.length - 1].visible = false;
    // show only one needle
    this.needles[this.needles.length - 1].visible = false;
    this.model.position.y = 1.5;
    this.scene.add(this.model);

    const plane = new THREE.PlaneGeometry();
    const material = new THREE.MeshNormalMaterial({
      side: THREE.DoubleSide,
    });
    const mesh = new THREE.Mesh(plane, this.wireMaterial);
    mesh.position.z = 1;
    mesh.scale.set(3.0, 3.0, 3.0);
    // this.scene.add(mesh);
  }

  setAnimations() {
    this.animation = {};
    this.animation.mixer = new THREE.AnimationMixer(this.model);
    this.CameraActions = {}; // Actions for the first mesh
    this.NeedleActions = {}; // Actions for the second mesh

    this.resource.animations.forEach((clip) => {
      const action = this.animation.mixer.clipAction(clip);
      if (clip.name.includes("Camera")) {
        this.CameraActions[clip.name] = action;
        this.CameraActions[clip.name].loop = THREE.LoopOnce;
        this.CameraActions[clip.name].clampWhenFinished = true; // Stop on the last frame
      } else if (clip.name.includes("Needle")) {
        this.NeedleActions[clip.name] = action;
        this.NeedleActions[clip.name].loop = THREE.LoopOnce;
        this.NeedleActions[clip.name].clampWhenFinished = true; // Stop on the last frame
      }
    });

    this.activeCameraAction =
      this.CameraActions[`CameraAction0${this.currentActionIndex}`];
    this.activeCameraAction.paused = false;
  }

  goToNext() {
    if (this.activeCameraAction) {
      // Smoothly transition out of the current action
      this.activeCameraAction.fadeOut(0.5);
    }

    const actionName = `CameraAction0${this.currentActionIndex}`;
    console.log("goToNext", actionName);
    // Set and play the next action with a fade-in
    this.activeCameraAction = this.CameraActions[actionName];
    this.activeCameraAction.timeScale = 1;
    this.activeCameraAction.reset().fadeIn(0.5).play();

    //Loop back to the first action if we've reached the end
    if (this.currentActionIndex === 4) {
      this.currentActionIndex = 1;
    } else {
      this.currentActionIndex++;
    }
  }

  goToPrev() {
    if (this.activeCameraAction) {
      // Smoothly transition out of the current action
      this.activeCameraAction.fadeOut(0.5);
    }
    if (this.currentActionIndex === 1) {
      this.currentActionIndex = 4;
    } else {
      this.currentActionIndex--;
    }
    const actionName = `CameraActionReversed0${this.currentActionIndex}`;
    console.log("goToPrev", actionName);
    // Set and play the next action with a fade-in
    this.activeCameraAction = this.CameraActions[actionName];
    this.activeCameraAction.reset().fadeIn(0.5).play();
  }

  goToIndex(index) {
    if (this.activeCameraAction) {
      // Smoothly transition out of the current action
      this.activeCameraAction.fadeOut(0.5);
    }

    this.currentActionIndex = index + 1;
    const actionName = `CameraAction0${this.currentActionIndex}`;
    console.log("goToIndex", actionName);
    // Set and play the next action with a fade-in
    this.activeCameraAction = this.CameraActions[actionName];
    this.activeCameraAction.reset().fadeIn(0.5).play();
  }

  setPen(model) {
    if (model === "short") {
      this.longPen.forEach((child) => {
        child.visible = false;
      });
      this.shortPen.forEach((child) => {
        child.visible = true;
      });
      this.wires[0].visible = false;
      this.wires[1].visible = true;
    } else if (model === "long") {
      this.longPen.forEach((child) => {
        child.visible = true;
      });

      this.shortPen.forEach((child) => {
        child.visible = false;
      });
      this.wires[1].visible = false;
      this.wires[0].visible = true;
    }
  }

  setTip(index) {
    if (index === 0) {
      this.needles.forEach((child) => {
        child.visible = false;
      });
    } else if (index % 2) {
      this.needles[0].visible = false;
      this.needles[1].visible = true;
    } else if (!(index % 2)) {
      this.needles[0].visible = true;
      this.needles[1].visible = false;
    }
  }

  setDebug() {
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("Camera");
      this.debugFolder
        .add(this.options, "cameraSpeed")
        .min(0.1)
        .max(2)
        .step(0.0001)
        .name("Speed");
      this.debugFolder
        .add(this.options, "minX")
        .min(0.1)
        .max(25)
        .step(0.01)
        .onChange(() => {
          this.wireMaterial.uniforms.minX.value = this.options.minX;
        });
      this.debugFolder
        .add(this.options, "maxX")
        .min(0.1)
        .max(25)
        .step(0.01)
        .onChange(() => {
          this.wireMaterial.uniforms.maxX.value = this.options.maxX;
        });
    }
  }

  update() {
    if (this.animation)
      this.animation.mixer.update(
        this.time.delta * (this.options.cameraSpeed / 1000)
      );
    if (this.cameraModel) {
      this.camera.setPositionAndRotation(
        this.cameraModel.position,
        this.cameraModel.rotation
      );
    }
  }
}
