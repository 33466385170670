import * as THREE from "three";
import Experience from "../Experience";

export default class Floor {
  constructor() {
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.scene = this.experience.scene;
    this.time = this.experience.time;

    // Options
    this.options = {};

    // Setup
    this.setModel();

    // Debug
    // this.setDebug();
  }

  setModel() {
    this.geometry = new THREE.PlaneGeometry();
    this.material = new THREE.ShadowMaterial({
      opacity: 0.2,
    });

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.mesh.rotation.x = -Math.PI * 0.5;
    this.mesh.scale.set(3, 3, 3);
    this.mesh.position.set(0, -0.1, 0.8);
    this.mesh.receiveShadow = true;
    this.scene.add(this.mesh);
  }

  setDebug() {
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("Floor");
    }
  }

  update() {}
}
